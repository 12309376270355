import React from 'react'
import TableHeader from '../TableHeader'
import { Row } from './styled'

export default function GenericTable({ columns, headers, data, onRowClick }) {
  const handleRowClick = (data) => {
    return onRowClick(data)
  }
  return (
    <table>
      <TableHeader columns={columns} headers={headers} />
      {data?.map((row) => {
        return (
          <Row columns={columns} key={row.id} onClick={handleRowClick(row)}>
            {headers.map((header) => {
              const columnValue = row?.[header.key]
              if (header?.component) {
                const Component = header?.component
                return <Component value={columnValue} />
              }
              if (typeof columnValue === 'boolean') {
                return columnValue ? 'Yes' : 'No'
              }
              if (header.key === 'action') {
                return <button type="button">{header.label}</button>
              }
              return <div>{row?.[header.key]}</div>
            })}
          </Row>
        )
      })}
    </table>
  )
}
