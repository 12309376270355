import React, { useState } from 'react'
import { Loading } from '@winnin/insights_ui'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import getCountry from '../../utils/getCountry'
import getRole from '../../utils/getRole'
import { Row, Data, WithLegend, List, Role } from './styled'
import TableHeader from '../TableHeader'
import Tooltip from '../Tooltip'

dayjs.extend(utc)

const UsersTable = ({ columns, headers, data, handleEdit, loading }) => {
  const [activeTooltip, setActiveTooltip] = useState(null)

  const isTooltipVisible = (id) => {
    return id === activeTooltip
  }

  const generateEmailStrting = (rawEmail) => {
    if (!rawEmail) return '-'
    return rawEmail?.length > 20 ? `${rawEmail.slice(0, 20)}...` : rawEmail
  }

  const generateCountryString = (rawCountry) => {
    if (!rawCountry) return '-'

    return getCountry(rawCountry)?.length > 10
      ? `${getCountry(rawCountry).slice(0, 10)}...`
      : getCountry(rawCountry)
  }

  const renderTable = () => {
    if (loading) return <Loading />

    if (data?.length) {
      return (
        <>
          {data?.map((row) => (
            <Row
              columns={columns}
              key={`${row.id}-${row.email}`}
              onClick={() => handleEdit(row)}
            >
              <Role active={row.role}>{getRole(row.role)}</Role>

              {row.name?.length > 20 || row.email?.length > 20 ? (
                <WithLegend
                  onMouseEnter={() => setActiveTooltip(`${row.id}-name`)}
                  onMouseLeave={() => setActiveTooltip(null)}
                >
                  {row.name?.length > 20
                    ? `${row.name.slice(0, 20)}...`
                    : row.name}

                  <span>{generateEmailStrting(row.email)}</span>

                  <Tooltip visible={isTooltipVisible(`${row.id}-name`)}>
                    <p>{row.name}</p>
                    <p>{row.email || '-'}</p>
                  </Tooltip>
                </WithLegend>
              ) : (
                <WithLegend>
                  {row.name}
                  <span>{row.email || '-'}</span>
                </WithLegend>
              )}

              {row.company.companyType.name?.length > 10 ? (
                <Data
                  onMouseEnter={() => setActiveTooltip(`${row.id}-company`)}
                  onMouseLeave={() => setActiveTooltip(null)}
                >
                  {`${row.company.companyType.name.slice(0, 10)}...`}
                  <Tooltip visible={isTooltipVisible(`${row.id}-company`)}>
                    <p>{row.company.companyType.name}</p>
                  </Tooltip>
                </Data>
              ) : (
                <Data>{row.company.companyType.name || '-'}</Data>
              )}

              {row.company.name?.length > 10 ||
              (row.country && getCountry(row.country)?.length > 10) ? (
                <WithLegend
                  onMouseEnter={() => setActiveTooltip(`${row.id}-company`)}
                  onMouseLeave={() => setActiveTooltip(null)}
                >
                  {row.company.name?.length > 10
                    ? `${row.company.name.slice(0, 10)}...`
                    : row.company.name}

                  <span>{generateCountryString(row.country)}</span>

                  <Tooltip visible={isTooltipVisible(`${row.id}-company`)}>
                    <p>{row.company.name}</p>
                    <p>{row.country ? getCountry(row.country) : '-'}</p>
                  </Tooltip>
                </WithLegend>
              ) : (
                <WithLegend>
                  {row.company.name}
                  <span>{row.country ? getCountry(row.country) : '-'}</span>
                </WithLegend>
              )}

              {row.brands?.length > 2 ? (
                <Data>
                  <List
                    onMouseEnter={() => setActiveTooltip(`${row.id}-board`)}
                    onMouseLeave={() => setActiveTooltip(null)}
                  >
                    <li>{row.brands[0].name}</li>
                    <li>{row.brands[1].name}...</li>

                    <Tooltip visible={isTooltipVisible(`${row.id}-board`)}>
                      <ul>
                        {row.brands.slice(2).map((brand) => (
                          <li key={brand}>{brand.name}</li>
                        ))}
                      </ul>
                    </Tooltip>
                  </List>
                </Data>
              ) : (
                <Data>
                  {row.brands?.length ? (
                    <List>
                      {row.brands.map((brand) => (
                        <li key={brand.name}>{brand.name}</li>
                      ))}
                    </List>
                  ) : (
                    '-'
                  )}
                </Data>
              )}

              <Data>
                {dayjs().utc().format('HH:mm')}
                <br />
                {dayjs().utc().format('DD/MM/YYYY')}
              </Data>
            </Row>
          ))}
        </>
      )
    }

    return <Row empty />
  }

  return (
    <table>
      <thead>
        <TableHeader columns={columns} headers={headers} />
      </thead>

      {renderTable()}
    </table>
  )
}

export default UsersTable
