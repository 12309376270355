import React, { useState, useEffect } from 'react'
import { Button } from '@winnin/insights_ui'
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks'
import Input, { Label } from '../Input'
import Modal from '../Modal'
import StatusSwitch from '../StatusSwitch'
import { ContentWrapper, Row } from './styled'
import {
  CREATE_PLAN,
  UPDATE_PLAN,
  DELETE_PLAN,
  GET_TREATMENT_OPTIONS,
  CREATE_TREATMENT_OPTION,
} from './query'
import { useToastStore } from '../../stores/ToastStore'
import Select from '../Select'
import findCsTreatment from '../../utils/getCSTreatmentOption'

export default function PlansModal({ isOpen, onRequestClose, activePlan }) {
  const [formState, setFormState] = useState(null)
  const [isDeleteButton, setIsDeleteButton] = useState(false)
  const [csTreatmentOptions, setCsTreatmentOption] = useState(null)
  const [isCreateOptionEnabled, setIsCreateOptionEnabled] = useState(false)
  const [newTreatmentOption, setNewTreatmentOption] = useState(null)
  const { dispatch } = useToastStore()
  const client = useApolloClient()

  const { data: csTreatment, loading: isCsTreatmentLoading } = useQuery(
    GET_TREATMENT_OPTIONS
  )

  const [createPlan, { loading }] = useMutation(CREATE_PLAN, {
    onCompleted() {
      onRequestClose()
    },
    onError(error) {
      console.error('PlansModal @ create >>>>>', error)
      dispatch({
        type: 'create',
        message: 'Oops! An error occurred. Please, try again.',
      })
    },
    refetchQueries: ['listPlans'],
    client,
  })

  const [updatePlan, { loading: updateLoading }] = useMutation(UPDATE_PLAN, {
    onCompleted() {
      onRequestClose()
    },
    onError(error) {
      console.error('PlansModal @ update >>>>>', error)
      dispatch({
        type: 'create',
        message: 'Oops! An error occurred. Please, try again.',
      })
    },
    refetchQueries: ['listPlans'],
    client,
  })

  const [deletePlan, { loading: deleteLoading }] = useMutation(DELETE_PLAN, {
    onCompleted() {
      onRequestClose()
    },
    onError(error) {
      console.error('PlansModal @ delete >>>>>', error)
      dispatch({
        type: 'create',
        message: 'Oops! An error occurred. Please, try again.',
      })
    },
    refetchQueries: ['listPlans'],
    client,
  })

  const [createTreatmentOption, { loading: loadingCreateOption }] = useMutation(
    CREATE_TREATMENT_OPTION,
    {
      onCompleted() {
        dispatch({
          type: 'create',
          message: 'Option created.',
        })
      },
      onError(error) {
        console.error('PlansModal @ create treatment option >>>>>', error)
        dispatch({
          type: 'create',
          message: 'Oops! An error occurred. Please, try again.',
        })
      },
      refetchQueries: ['listCsTreatmentTypes'],
      client,
    }
  )

  const getInitialFormState = () => {
    if (activePlan) {
      return {
        name: activePlan?.name,
        jobs: activePlan?.jobs,
        hasWinninReports: activePlan?.hasWinninReports,
        csTreatment: activePlan?.csTreatment,
        editors: activePlan?.editors,
        viewers: activePlan?.viewers,
        workspaces: activePlan?.workspaces,
        languages: activePlan?.languages,
        isActive: activePlan?.isActive,
      }
    }
    return {
      name: '',
      jobs: '',
      hasWinninReports: '',
      csTreatment: '',
      editors: '',
      viewers: '',
      workspaces: '',
      languages: '',
      isActive: '',
    }
  }

  useEffect(() => {
    const initialFormState = getInitialFormState()
    setFormState(initialFormState)
    return () => {
      setIsDeleteButton(false)
    }
  }, [activePlan])

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormState({ ...formState, [name]: value })
  }

  const handleSubmit = (e) => {
    e?.preventDefault()
    const variables = {
      name: formState?.name,
      jobs: Number(formState?.jobs),
      hasWinninReports: !!formState?.hasWinninReports,
      csTreatmentId: +formState?.csTreatment,
      editors: Number(formState?.editors),
      viewers: Number(formState?.viewers),
      workspaces: Number(formState?.workspaces),
      languages: Number(formState?.languages),
    }
    if (!activePlan) {
      createPlan({
        variables,
      })
      return
    }
    updatePlan({
      variables: {
        id: activePlan?.id,
        ...variables,
      },
    })
  }

  const handleDelete = () => {
    if (!isDeleteButton) {
      setIsDeleteButton(true)
      return
    }
    deletePlan({
      variables: {
        id: activePlan?.id,
      },
    })
  }

  const handleCSTreatment = () => {
    if (!isCreateOptionEnabled) {
      setIsCreateOptionEnabled(true)
      return
    }
    if (!newTreatmentOption) {
      dispatch({
        type: 'create',
        message: 'Please add a name to the option.',
      })
      return
    }
    createTreatmentOption({
      variables: {
        name: newTreatmentOption,
      },
    })
  }

  useEffect(() => {
    if (csTreatment) {
      const formattedOptions = csTreatment?.listCsTreatmentTypes.map((item) => {
        return {
          value: item.id,
          label: item.name,
        }
      })
      setCsTreatmentOption(formattedOptions)
    }
  }, [csTreatment])

  useEffect(() => {
    if (!isOpen) {
      setIsCreateOptionEnabled(false)
    }
  }, [isOpen])

  const isLoading =
    loading || updateLoading || deleteLoading || isCsTreatmentLoading

  const csTreatmentValue = findCsTreatment(
    formState?.csTreatment,
    csTreatmentOptions
  )

  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <ContentWrapper as="form" onSubmit={handleSubmit}>
        <Row>
          <h2>Plan</h2>
        </Row>
        <Row>
          <Row.Column>
            <Label>Title</Label>
            <Input
              name="name"
              value={formState?.name}
              onChange={handleChange}
              type="text"
              required
            />
          </Row.Column>
          <Row.Column>
            <Label>CS treatment</Label>
            <Select
              value={csTreatmentValue}
              onChange={({ value }) =>
                setFormState({ ...formState, csTreatment: value })
              }
              options={csTreatmentOptions}
              placeholder="Select Type"
            />
          </Row.Column>
        </Row>
        <Row>
          <Row.Column>
            <Label>Has Winnin Reports</Label>
            <StatusSwitch
              setActive={(value) => {
                setFormState({ ...formState, hasWinninReports: value })
              }}
              active={formState?.hasWinninReports}
            />
          </Row.Column>
          <Row.Column>
            <Label>Is active</Label>
            <StatusSwitch active={formState?.isActive} />
          </Row.Column>
        </Row>
        <Row>
          <Row.Column>
            <Label>N. of Editors</Label>
            <Input
              name="editors"
              value={formState?.editors}
              onChange={handleChange}
              type="number"
              required
            />
          </Row.Column>
          <Row.Column>
            <Label>N. of viewers</Label>
            <Input
              name="viewers"
              value={formState?.viewers}
              onChange={handleChange}
              type="number"
              required
            />
          </Row.Column>
        </Row>
        <Row>
          <Row.Column>
            <Label>N. of languages</Label>
            <Input
              name="languages"
              value={formState?.languages}
              onChange={handleChange}
              type="number"
              required
            />
          </Row.Column>
          <Row.Column>
            <Label>N. of workspaces</Label>
            <Input
              name="workspaces"
              value={formState?.workspaces}
              onChange={handleChange}
              type="number"
              required
            />
          </Row.Column>
        </Row>
        <Row>
          <Row.Column>
            <Label>N. of jobs</Label>
            <Input
              name="jobs"
              value={formState?.jobs}
              onChange={handleChange}
              type="number"
              required
            />
          </Row.Column>
        </Row>

        {isCreateOptionEnabled ? (
          <Row>
            <Row.Column as="form">
              <Label>CS Treatment Option Name</Label>
              <Input
                name="newCSTreatmentOption"
                value={newTreatmentOption}
                onChange={(e) => {
                  setNewTreatmentOption(e.target.value)
                }}
              />
            </Row.Column>
          </Row>
        ) : null}

        <Row>
          <Button
            type="button"
            onClick={handleCSTreatment}
            disabled={loadingCreateOption}
          >
            {isCreateOptionEnabled ? 'Create' : 'Add'} CS Treatment Option
          </Button>
          <div>
            {activePlan && !isDeleteButton && (
              <Button
                type="button"
                appearance="outline"
                onClick={handleDelete}
                disabled={isLoading}
              >
                Delete plan
              </Button>
            )}
            {isDeleteButton && (
              <Button
                type="button"
                appearance="outline"
                onClick={handleDelete}
                disabled={isLoading}
              >
                Are you sure you want to delete this plan?
              </Button>
            )}
            <Button type="submit" disabled={isLoading}>
              {activePlan ? 'Update' : 'Create'}
            </Button>
          </div>
        </Row>
      </ContentWrapper>
    </Modal>
  )
}
