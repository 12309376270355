import React from 'react'
import { Switch, Route } from 'react-router-dom'
import PrivateRoute from './components/PrivateRoute'
import Error404 from './components/Error404'
import WorkspacesPage from './pages/WorkspacesPage'
import CompaniesPage from './pages/CompaniesPage'
import UsersPage from './pages/UsersPage'
import Login from './pages/LoginPage'
import ErrorBoundary from './components/ErrorBoundary'
import PlansPage from './pages/PlansPage'

const Router = () => (
  <div>
    <Switch>
      <Route path="/login" exact component={Login} />
      <PrivateRoute path="/companies">
        <ErrorBoundary key="companies">
          <CompaniesPage />
        </ErrorBoundary>
      </PrivateRoute>

      <PrivateRoute path="/workspaces">
        <ErrorBoundary key="workspaces">
          <WorkspacesPage />
        </ErrorBoundary>
      </PrivateRoute>

      <PrivateRoute path="/users">
        <ErrorBoundary key="users">
          <UsersPage />
        </ErrorBoundary>
      </PrivateRoute>

      {/* <PrivateRoute path="/plans">
        <ErrorBoundary key="plans">
          <PlansPage />
        </ErrorBoundary>
      </PrivateRoute> */}

      <PrivateRoute path="*" component={Error404} />
    </Switch>
  </div>
)

export default Router
