import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import Page from '../../components/Page'
import Header from '../../components/Header'
import { LIST_PLANS } from './query'
import PlansModal from '../../components/PlansModal'
import GenericTable from '../../components/GenericTable'
import TablePagination from '../../components/TablePagination'
import { TABLE_PAGINATION_ROWS_PER_PAGE_OPTS } from '../../consts/pagination'

export default function PlansPage() {
  const [isOpen, setIsOpen] = useState(false)
  const [activePlan, setActivePlan] = useState(null)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(
    TABLE_PAGINATION_ROWS_PER_PAGE_OPTS[0]
  )

  const { data, loading, fetchMore } = useQuery(LIST_PLANS, {
    fetchpolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })

  const label = { singular: 'Plan', plural: 'Plans' }
  const columns = '120px 120px 120px 120px 120px 120px 120px 120px'

  const headers = [
    {
      label: 'Status',
      key: 'isActive',
      component: ({ value }) => {
        return <div>{value ? 'Active' : 'Inactive'}</div>
      },
    },
    { label: 'Title', key: 'name' },
    { label: 'Has Winnin Reports', key: 'hasWinninReports' },
    { label: 'CS Treatment', key: 'csTreatment' },
    { label: 'Viewers', key: 'viewers' },
    { label: 'Jobs', key: 'jobs' },
    { label: 'Workspaces', key: 'workspaces' },
    { label: 'Languages', key: 'languages' },
  ]

  const handleModal = () => {
    setIsOpen((oldState) => !oldState)
  }

  const handleEdit = (row) => {
    return () => {
      setActivePlan(row)
      setIsOpen(true)
    }
  }

  const handleCloseModal = () => {
    setIsOpen(false)
    setActivePlan(null)
  }

  const loadMore = async () => {
    if (loading) return

    try {
      await fetchMore({
        variables: {
          offset: rowsPerPage.value * page,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult.listPlans.entities) {
            return prev
          }
          if (fetchMoreResult.listPlans.entities.length === 0) {
            return prev
          }
          return {
            ...prev,
            listPlans: { ...fetchMoreResult.listPlans },
          }
        },
      })
    } catch (error) {
      console.error('CompaniesPage @ loadMore >>>>>>>>>>>', { error })
    }
  }

  useEffect(() => {
    loadMore()
  }, [page])

  return (
    <Page maxWidth={980}>
      <Header
        length={data?.listPlans?.rows}
        action={handleModal}
        label={label}
      />
      {data?.listPlans?.entities?.length > 0 ? (
        <>
          <GenericTable
            columns={columns}
            headers={headers}
            data={data?.listPlans?.entities}
            loading={loading}
            onRowClick={handleEdit}
          />
          <TablePagination
            count={data?.listPlans?.rows}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={(newPage) => setPage(newPage)}
            onChangeRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
          />
        </>
      ) : (
        <p>No plans</p>
      )}
      <PlansModal
        isOpen={isOpen}
        onRequestClose={handleCloseModal}
        activePlan={activePlan}
      />
    </Page>
  )
}
