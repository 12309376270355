import React, { useRef, useState, useCallback, useEffect } from 'react'
import { useQuery, useLazyQuery } from '@apollo/react-hooks'
import { Loading } from '@winnin/insights_ui'
import dayjs from 'dayjs'
import Filters from '../../components/Filters'
import getCountry from '../../utils/getCountry'
import Page from '../../components/Page'
import TablePagination from '../../components/TablePagination'
import BoardModal from '../../components/BoardModal'
import BoardsTable from '../../components/BoardsTable'
import Error from '../../components/Error'
import Header from '../../components/Header'
import { WORKSPACES_QUERY, COMPANIES_QUERY } from './query'
import { TABLE_PAGINATION_ROWS_PER_PAGE_OPTS } from '../../consts/pagination'
import sortByLabel from '../../utils/sortByLabel'

const WorkspacesPage = () => {
  // state
  const [sortField, setSortField] = useState('name')
  const [sortOrder, setSortOrder] = useState('asc')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [page, setPage] = useState(0)
  const [filters, setFilters] = useState({
    companyIds: [],
    countries: [],
  })
  const [workspaceModal, setWorkspaceModal] = useState(null)
  const [availableCountries, setAvailableCountryOptions] = useState([])
  const [initialLoading, setInitialLoading] = useState(true)
  const [rowsPerPage, setRowsPerPage] = useState(
    TABLE_PAGINATION_ROWS_PER_PAGE_OPTS[0]
  )
  const now = useRef(dayjs().unix())

  // queries and mutations
  const {
    loading: workspacesLoading,
    data: workspacesData,
    error: workspacesError,
    refetch: workspacesRefetch,
    fetchMore: workspacesFetchMore,
  } = useQuery(WORKSPACES_QUERY, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      timestamp: now.current,
      limit: rowsPerPage.value,
      offset: 0,
      ...filters,
      sort: {
        field: 'name',
        order: 'asc',
      },
    },
    onCompleted: ({ workspaces }) => {
      setAvailableCountryOptions(
        availableCountries.length
          ? availableCountries
          : Array.from(
              new Set(workspaces.entities.map((company) => company.country))
            )
              .map((country) => ({
                label: getCountry(country),
                value: country,
              }))
              .sort(sortByLabel)
      )
      setInitialLoading(false)
    },
    onError: useCallback((error) => {
      console.error('WorkspacesPage @ useQuery >>>>>', { error })
      setInitialLoading(false)
    }, []),
  })

  const [
    getFilterOptions,
    {
      loading: filterOptionsLoading,
      data: filterOptionsData,
      error: filterOptionsError,
    },
  ] = useLazyQuery(COMPANIES_QUERY, {
    onError: useCallback((error) => {
      console.error('WorkspacesPage @ FILTER_OPTIONS_QUERY >>>>>', { error })
    }, []),
  })

  // effects
  useEffect(() => {
    getFilterOptions()
  }, [])

  useEffect(() => {
    loadMore()
  }, [page, rowsPerPage])

  // functions
  const handleSort = (key) => {
    if (sortField === key) {
      const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc'

      setSortOrder(newSortOrder)
      workspacesRefetch({
        sort: {
          field: key,
          order: newSortOrder,
        },
      })
    } else {
      setSortField(key)
      setSortOrder('asc')
      workspacesRefetch({
        sort: {
          field: key,
          order: 'asc',
        },
      })
    }
  }

  const handleFilter = (filter, id) => {
    setFilters((prev) => ({ ...prev, [filter]: !id ? [] : [id] }))
    workspacesRefetch({
      [filter]: !id ? [] : [id],
    })
  }

  const handleEdit = (user = null) => {
    setIsModalOpen(true)
    setWorkspaceModal(user)
  }

  const loadMore = async () => {
    if (workspacesLoading) return
    try {
      await workspacesFetchMore({
        variables: {
          ...filters,
          offset: rowsPerPage.value * page,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult.workspaces) return prev
          return {
            ...prev,
            workspaces: { ...fetchMoreResult.workspaces },
          }
        },
      })
    } catch (error) {
      console.error('WorkspacesPage @ loadMore >>>>>>>>>>>', { error })
    }
  }

  // rendering
  if (workspacesError) {
    return <Error />
  }

  const label = { singular: 'Workspace', plural: 'Worksapces' }

  const headers = [
    { label: 'Status' },
    {
      label: 'Panel',
      sortable: true,
      onClick: () => handleSort('name'),
      active: sortField === 'name',
      sort: sortOrder,
    },
    {
      label: 'Company',
      sortable: true,
      onClick: () => handleSort('company'),
      active: sortField === 'company',
      sort: sortOrder,
    },
    { label: 'Country' },
    { label: 'Users' },
  ]

  const filterObj = filterOptionsError
    ? undefined
    : {
        width: '234px',
        selects: [
          {
            placeholder: 'Select Company',
            options: filterOptionsData?.companies2.entities
              ?.map(({ id, name }) => ({
                label: name,
                value: id,
              }))
              ?.sort(sortByLabel),
            onChange: (option) => handleFilter('companyIds', option?.value),
            isClearable: true,
          },
          {
            placeholder: 'Select Country',
            options: availableCountries,
            onChange: (option) => handleFilter('countries', option?.value),
            isClearable: true,
          },
        ],
      }

  return (
    <Page maxWidth={638}>
      <Filters filters={filterObj} />
      {initialLoading || filterOptionsLoading || workspacesLoading ? (
        <Loading topMargin />
      ) : (
        <>
          <Header
            length={workspacesData?.workspaces?.rows || ''}
            action={handleEdit}
            label={label}
          />
          <BoardsTable
            columns="107px 170px 170px 107px 84px"
            headers={headers}
            data={workspacesData?.workspaces?.entities}
            handleEdit={handleEdit}
            loading={workspacesLoading}
          />
          <TablePagination
            leftSpacing="25%"
            count={workspacesData?.workspaces?.rows}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={(newPage) => setPage(newPage)}
            onChangeRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
          />
          <BoardModal
            onRequestClose={() => setIsModalOpen(false)}
            isOpen={isModalOpen}
            brand={workspaceModal}
            onCreateOrEdit={workspacesRefetch}
          />
        </>
      )}
    </Page>
  )
}

export default WorkspacesPage
