import { gql } from 'apollo-boost'

export const CREATE_PLAN = gql`
  mutation createPlan(
    $name: String!
    $jobs: Int!
    $hasWinninReports: Boolean!
    $csTreatmentId: Int!
    $editors: Int!
    $viewers: Int!
    $workspaces: Int!
    $languages: Int!
  ) {
    createPlan(
      planInput: {
        name: $name
        jobs: $jobs
        hasWinninReports: $hasWinninReports
        csTreatmentId: $csTreatmentId
        editors: $editors
        viewers: $viewers
        workspaces: $workspaces
        languages: $languages
      }
    ) {
      success
      message
    }
  }
`

export const UPDATE_PLAN = gql`
  mutation updatePlan(
    $id: ID!
    $name: String!
    $jobs: Int!
    $hasWinninReports: Boolean!
    $csTreatmentId: Int!
    $editors: Int!
    $viewers: Int!
    $workspaces: Int!
    $languages: Int!
  ) {
    updatePlan(
      id: $id
      planInput: {
        name: $name
        jobs: $jobs
        hasWinninReports: $hasWinninReports
        csTreatmentId: $csTreatmentId
        editors: $editors
        viewers: $viewers
        workspaces: $workspaces
        languages: $languages
      }
    ) {
      success
      message
    }
  }
`

export const DELETE_PLAN = gql`
  mutation deletePlan($id: ID!) {
    deletePlan(id: $id) {
      success
      message
    }
  }
`

export const CREATE_TREATMENT_OPTION = gql`
  mutation createCsTreatmentType($name: String!) {
    createCsTreatmentType(name: $name) {
      success
      message
    }
  }
`

export const GET_TREATMENT_OPTIONS = gql`
  query listCsTreatmentTypes {
    listCsTreatmentTypes {
      id
      name
    }
  }
`
