import { gql } from 'apollo-boost'

export const LIST_PLANS = gql`
  query listPlans($limit: Int = 10, $offset: Int = 0) {
    listPlans(limit: $limit, offset: $offset) {
      entities {
        id
        name
        jobs
        hasWinninReports
        csTreatment
        editors
        viewers
        workspaces
        languages
        isActive
      }
      rows
    }
  }
`
