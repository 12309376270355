import styled from 'styled-components'

export const ContentWrapper = styled.div`
  width: 100%;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  column-gap: 18px;
  margin-bottom: 18px;
`

Row.Column = styled.div`
  flex: 1;
  width: 100%;
`
