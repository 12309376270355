import styled from 'styled-components'
import { COLORS } from '../../theme/colors'

export const Row = styled.tr`
  ${({ loading }) => {
    return loading
      ? `
      box-shadow: 0 0 0 1px ${COLORS.warning};
    `
      : ''
  }}
  height: 64px;
  grid-template-columns: ${({ columns }) => columns};
  background: ${COLORS.base_color2};
  display: grid;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  text-align: center;
  margin-bottom: 5px;
  transition: box-shadow 200ms;
  color: black;
  &:first-child {
    height: 48px;
  }

  &:not(:first-child):hover {
    box-shadow: ${COLORS.shadow_light};
    cursor: pointer;
  }
`
